<template>
    <HamburgerMenu></HamburgerMenu>
</template>

<script>
import HamburgerMenu from "./Header/HamburgerMenu"
export default {
    name: "Header",
    components: {
        HamburgerMenu,
    }
}
</script>