<template>
    <div>
        <ContactForm />
        <FooterContents />
        <FooterCredits />
    </div>  
</template>

<script>
import FooterContents from "./Footer/FooterContents";
import FooterCredits from "./Footer/FooterCredits";
import ContactForm from "./Footer/ContactForm";

export default {
    name: "Footer",
    components: {
        FooterContents,
        FooterCredits,
        ContactForm,
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: $mobile) { 
    div{
        padding-top: 2rem;
    }
}
</style>
