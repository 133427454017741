<template>
    <nav role="navigation" v-bind:class="{acceesibility: acceesibility}">
        <div id="menu_toggle"
              v-on:keyup.enter="menuShow = !menuShow">
            <input 
              type="checkbox"
              v-model="menuShow"
              :aria-label="ariaLabel"
              />
            <span v-if="!menuShow">MENU</span>
            <img tabindex="-1" class="btn_menu" :class="{ 'btn_close': menuShow }" :src="getOpenCloseIcon()" aria-label="Open Navigation Menu"/>
        </div>
        <div v-if="onPropertyPage" class="property-navigation">
          <span @click="prevProperty" class="property-controller-button icon-left">
            <img src="/images/icon/prev.svg" alt="Previous Property">
            Prev Property
          </span>
          <span @click="nextProperty" class="property-controller-button icon-right">
            Next Property
            <img src="/images/icon/next.svg" alt="Next Property">
          </span>
        </div>
        <router-link to="/" aria-label="Go to home page" class="home_link">
          <img src="/images/header-logo.png" class="top_logo" alt="Logo" />
        </router-link>
        <div v-if="onPropertyPage" class="map-controller">
            <span @click="toggleMap" class="property-controller-button icon-right map-control">
              {{ mapButtonText }}
              <img :src="$store.getters.showMap ? '/images/icon/hide_map.svg' : '/images/icon/show_map.svg'" alt="Map Control">
            </span>
        </div>
        <transition name="fade">
          <div id="menu" v-if="menuShow"> 
            <ul>
              <li>
                <router-link :to="menuItems[0].link" @click.native="linkClick" :aria-label="`Go to ${$t(`message.menu.${menuItems[0].label}`)} page`">
                  {{ $t(`message.menu.${menuItems[0].label}`) }}
                </router-link>
              </li>
              <li>
                <a :href="menuItems[1].link" target="_blank" :aria-label="`Go to ${$t(`message.menu.${menuItems[1].label}`)} page`">
                  {{ $t(`message.menu.${menuItems[1].label}`) }}
                </a>
              </li>
              <li>
                <router-link :to="menuItems[2].link" @click.native="linkClick" :aria-label="`Go to ${$t(`message.menu.${menuItems[2].label}`)} page`">
                  {{ $t(`message.menu.${menuItems[2].label}`) }}
                </router-link>
              </li>
              <li>
                <router-link :to="menuItems[3].link" @click.native="linkClick" :aria-label="`Go to ${$t(`message.menu.${menuItems[3].label}`)} page`">
                  {{ $t(`message.menu.${menuItems[3].label}`) }}
                </router-link>
              </li>
              <li>
                <router-link :to="menuItems[4].link" @click.native="linkClick" :aria-label="`Go to ${$t(`message.menu.${menuItems[4].label}`)} page`">
                  {{ $t(`message.menu.${menuItems[4].label}`) }}
                </router-link>
              </li>
              <li>
                <router-link :to="menuItems[5].link" @click.native="linkClick" :aria-label="`Go to ${$t(`message.menu.${menuItems[5].label}`)} page`">
                  {{ $t(`message.menu.${menuItems[5].label}`) }}
                </router-link>
              </li>
              <li>
                <router-link to="/community-matters" @click.native="linkClick" :aria-label="`Go to Community matters page`">
                  Community Matters
                </router-link>
              </li>
              <li>
                <router-link :to="menuItems[6].link" @click.native="linkClick" :aria-label="`Go to ${$t(`message.menu.${menuItems[6].label}`)} page`">
                  {{ $t(`message.menu.${menuItems[6].label}`) }}
                </router-link>
              </li>
              <li>
                <router-link class="btn btn_login" to="/login">
                  Client Login
                </router-link>
              </li>
            </ul>
            <hr />
            <FooterSocialMedia></FooterSocialMedia>
          </div>
        </transition>
    </nav>
</template>

<script>

import FooterSocialMedia from "../Footer/FooterSocialMedia"

export default {
    name: 'Menu',
    components: {
      FooterSocialMedia
    },
    data(){
        return{ 
          menuItems: [
              {
                  label: "home",
                  link: "/"
              },
              {
                  label: "buy",
                  link: "https://www.cerdazeinrealestate.com/search/advanced_search/"
              },
              {
                  label: "forSale",
                  link: "/properties/for-sale"
              },
              {
                  label: "forLease",
                  link: "/properties/rental"
              },
              {
                  label: "service",
                  link: "/service"
              },
              {
                  label: "team",
                  link: "/team"
              },
              {
                  label: "inquire",
                  link: "/inquire"
              },
          ],
          menuShow: false,
      }
    },
    methods: {
      linkClick(){
        this.menuShow = false;
      },
      getOpenCloseIcon(){
        return this.menuShow ? '/images/hamburger-close.png' : '/images/hamburger.png';
      },
      toggleMap(){
        this.$store.dispatch('toggleMap');
      },
      nextProperty(){
        this.$router.push(this.$store.getters.nextPropertyURL);
      },
      prevProperty(){
        this.$router.push(this.$store.getters.prevPropertyURL);
      },
    },
    computed:{
      ariaLabel:function(){
        return !this.menuShow ? 'Open Navigation Menu' : 'Close Navigation Menu';
      },
      acceesibility(){
        return this.$store.getters.accessibilitySetting.showAccessibilityBar;
      },
      mapButtonText(){
        return this.$store.getters.showMap ? 'Hide Map' : 'Show Map';
      },
      onPropertyPage(){
        return this.$route.name === 'property';
      }
    },
    watch:{
      $route (){
          this.menuShow = false;
      }
    },
    
}
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
  position: sticky;
  top: 0;
  height: 47px;
  border-bottom: 1px solid #e1e1e1;
  z-index: 10;
  display: flex;
  justify-content: center;
  z-index: 20;
  background: white;
  &.acceesibility{
    top:44px;
  }
  .home_link{
    &:focus, &:visited{
      box-shadow: none;
      outline: none;
    }
  }
  #menu_toggle {
    position: absolute;
    top: 7px;
    right: 15px;
    z-index: 9990;
    user-select: none;
    display: flex;
    &:focus, &:visited{
      box-shadow: none;
      outline: none;
    }
    & input {
        display: block;
        width: 90px;
        height: 30px;
        position: absolute;
        left: -3px;
        cursor: pointer;
        opacity: 0; /* hide this */
        z-index: 2; /* and place it over the hamburger */
    }
    span{
      font-size: 12px;
      font-weight: 700;
      margin-right: 10px;
      margin-top: 10px;
    }
    .btn_menu {
        margin-top: 10px;
        width: 30px;
        height: 15px;
        &.btn_close{
            width:20px;
            height: 20px;
            margin-right: 6px;
        }
    }
  }
  .top_logo{
    left: 50%;
    top: 50%;
    position: absolute;
    width: 230px;
    height: 46px;
    transform: translate(-50%,-50%);
  }
  #menu {
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    display: block;
    background-color:rgba(0, 0, 0, 0.9);
    list-style-type: none;
    text-align: center;
    z-index: 10;
    font-family: $secondaryFont;
    font-weight: 400;
    ul {
        list-style-type: none;
        margin: 0 auto;
        margin-top: 40px;
        padding-inline-start: unset;
    }
    ul>li {
        color:#fff;
        padding: 16px 0;
        font-size: 2.5rem;
        &:hover{
        opacity: 0.85;
        }
    }
    a {
        color: #fff;
        text-decoration: none;
        &:visited{
            color: #fff;
        }
    }
    hr {
        width: 40%;
    }
  }
}
.btn_login{
  border: 1px #fff solid;
}

.map-controller{
  position: absolute;
  right: 140px;
  top: 9px;
}

.property-navigation{
  display: flex;
  gap: 10px;
  position: absolute;
  left: 20px;
  top: 9px;
}

.property-controller-button{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  padding: 6px 14px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.1px;
  cursor: pointer;
  border-radius: 14px;
  &.map-control{
    width: 140px;
  }
  &.icon-right{
    img{
      margin-left: 10px;
    }
  }
  &.icon-left{
    img{
      margin-right: 10px;
    }
  }
}

@media (max-width: $tablet) {
  .property-navigation, .map-controller{
    display: none;
  }
}

@media (max-width: $mobile) { 
  nav {
    .top_logo{
        left: 16px;
        top: 5px;
        transform: unset;
        width: 180px;
        height: 37px;
    }
    #menu{
        ul>li {
          font-size: 2rem;
          padding: 6px 0;
        }
    }
  }
}
</style>
