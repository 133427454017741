<template>
  <div id="app" role="main">
    <Accessibility />
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Accessibility from './components/Accessibility.vue'
export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Accessibility
  },
  mounted:function(){
      if(!this.$store.getters.properties.length){
          this.$store.dispatch('updateProperties');
          this.$store.dispatch('propertiesLoaded');
      } else if(this.$store.getters.checkExpiryDate){
          this.$store.dispatch('updateProperties');
      }
  },

}

import 'vueperslides/dist/vueperslides.css';
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i|Open+Sans:400,600,700|Work+Sans:200,400&display=swap');
@import url('https://use.typekit.net/hdu0mpc.css');
html, body{
  font-family: $primaryFont;
  position: relative;
  margin:0;
  padding:0;
  height: 100%;
  min-height: 100vh;
  width:100%;
}

*{
  box-sizing: border-box;
}

button:focus{
  outline: none;
}
p{
  font-size: 0.85rem;
  line-height: 1.5rem;
}

.btn{
  display: inline-block;
  font-family: $primaryFont;
  text-transform: uppercase;
  font-size: 0.75rem;
  color:#000;
  text-decoration: none;
  padding:10.5px 48px;
  border: 1px solid #000;
  margin: 1rem 0;
  letter-spacing: 1.5px;
  font-weight: 700;
  &:hover{
    opacity: 0.8;
  }
}

h1, h2, h3, h4, h5, h6{
  font-family: ivymode, sans-serif;
  font-weight: 200;
  font-style: normal;
}

h2{
  font-size: 2rem;
}

.vue-simple-spinner-text{
  letter-spacing: 3px;
  font-size: 1rem !important;
  color: #000 !important;
}
.multiselect__single{
  strong{
    font-weight: 400;
  }
}
.multiselect__option--selected{
  font-weight: 400 !important;
}

.gm-style-iw{
  width: 200px;
  button{
    display: none !important;
  }
  &-c{
    padding: 0 !important;
  }
  &-d{
    max-width: unset !important;
    overflow: auto !important;
  }
}
.gm-style-iw-t{
  bottom: 42px !important;
}
.gm-style .gm-style-iw-t::after{
  top: -1px !important;
}

.multiselect__option--selected.multiselect__option--highlight{
    background: #4c4c4c !important;
}
.multiselect__option--highlight{
    background:#4c4c4c !important;
}

.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before{
    box-shadow: none !important;
}

.vueperslides--fixed-height.vueperslides--bullets-outside{
    margin-bottom: 2em;
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before{
    box-shadow: none;
}
</style>
