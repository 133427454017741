import Vue from "vue"

Vue.filter(
    "priceFormat", function (value) {
        if (!value) return ''
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        })
        return formatter.format(value)
    }
)

Vue.filter(
    "startCase", function (value) {
        if(value === 'realEstateDuringCOVID19'){
            return 'Real Estate During COVID-19';
        }
        return Vue.lodash.startCase(value)
    }
)