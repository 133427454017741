<template>
    <div class="credits">
        <p>{{$t(`message.footer.copyright`, [year])}}</p>
        <p>{{$t(`message.footer.member`)}}</p>
        <p>{{$t(`message.footer.dre`)}}</p>
        <p>{{$t(`message.footer.infoReliable`)}}</p>

        <p class="uptown" v-html="$t(`message.footer.created`)"></p>
    </div>
</template>

<script>
export default {
    name: "FooterCredits",
    data(){
        var d = new Date();
        return{
            year: d.getFullYear(),
        }
    },
}
</script>

<style lang="scss" scoped>
.credits{
    background: #ddd;
    color: #3f3f3f;
    font-size: 0.65rem;
    padding-top: 64px;
    padding-bottom: 32px;
    text-align: center;
    p {
        margin: 0;
        padding: 0;
        font-size: 0.45rem;
        line-height: 1rem;
    }
    .uptown{
        font-family: $secondaryFont;
        margin-top: 32px;
        font-size: 1rem;
        line-height: 1.25rem;
        a{
            text-decoration: none;
            color: #000;
            &:hover{
                opacity: 0.8;
            }
        }
    }
}

@media (max-width: $mobile) {
    .credits{
        padding: 2rem;
        .uptown{
            margin: 1rem 0;
        }
    }
}
</style>

<style lang="scss">
.uptown a{
    text-decoration: none;
    color: #000;
    &:hover{
        opacity: 0.8;
    }
}
</style>