import Vue from 'vue';
import App from './App.vue';
import store from './store/index';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import * as VueGoogleMaps from 'vue2-google-maps';
//import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueLazyload from 'vue-lazyload';
import "./filters";
import ReadMore from 'vue-read-more';
import VueWindowSize from 'vue-window-size';
import VueSimpleAlert from "vue-simple-alert";
import vmodal from 'vue-js-modal';

//icons 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faYelp } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt, faEnvelope, faSearch, faCaretDown, faCaretUp, faPlusSquare, faMinusSquare, faArrowLeft, faArrowRight, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import AOS from "aos";
import "aos/dist/aos.css";

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

library.add(faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faPhoneAlt, faEnvelope, faSearch, faCaretDown, faCaretUp, faYelp, faPlusSquare, faMinusSquare, faArrowLeft, faArrowRight, faPlusCircle, faMinusCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueAxios, axios)
Vue.use(VueLazyload)
Vue.use(VueI18n)
Vue.use(ReadMore);
Vue.use(VueWindowSize);
Vue.use(VueSimpleAlert);
Vue.use(vmodal)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: '/images/loading.gif',
  attempt: 1
})

Vue.use(VueGoogleMaps, {
  load: {
    key:'AIzaSyClcrOhxKRmW9SCnOYIkol5B86zQJRpQoQ',
    //key: 'AIzaSyClcrOhxKRmW9SCnOYIkol5B86zQJRpQoQ',
    libraries: 'places',
  },
})

// Ready translated locale messages
const messages = {
  en: {
    message: require('./locales/en-US.json')
  },
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
})

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: lazyLoad('Home') },
    { path: '/service', component: lazyLoad('Service')},
    { path: '/service-new', component: lazyLoad('ServiceNew')},
    { path: '/rental-submission', component: lazyLoad('RentalForm')},
    { path: '/inquire', component: lazyLoad('Inquire')},
    { path: '/team', component: lazyLoad('Team')},
    { path: '/login', component: lazyLoad('Login')},
    { path: '/thankyou', component: lazyLoad('SubmissionSuccess')},
    { name: 'properties', path: '/properties/:category?', component: lazyLoad('Properties')},
    { path: '/property/:category/:slug', component: lazyLoad('Property'), name: 'property'},
    { path: '/agent/:slug', component: lazyLoad('Agent')},
    { path: '/clear-cache', component: lazyLoad('ClearCache')},
    { path: '/list-with-czre', component: lazyLoad('FlipbookView')},
    { path: '/community-matters', component: lazyLoad('ServiceNew')},
    { path: "*", component: lazyLoad('PageNotFound') },
  ],
  scrollBehavior (to, from, savedPosition) {
    if(savedPosition){
      return savedPosition
    }
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 10 }
      }
    }
    return { x: 0, y: 0 }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    AOS.init({
      duration: 800
    })
  },
}).$mount('#app')
