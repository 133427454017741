<template>
    <div id="contact" class="contact_form" v-if="$route.path !== '/test'">
        <h2>{{$t('message.contact.inquire')}}</h2>
        <hr />
        <p>{{$t('message.contact.subtitle')}}</p>
        <form 
            name="contact-form"
            method="post"
            class="kwes-form"
            action="https://kwesforms.com/api/foreign/forms/lEnltAuttNtTI38sCWtw"
            has-recaptcha-v2
            >
            <div class="form">
                <div class="info">
                    <input v-model="fullname" type="text" required rules="required" name="name" id="name" placeholder="Name"/>
                    <input v-model="email" type="email" required rules="required" name="email" id="email"  placeholder="Email"/>
                    <input v-model="phone" type="text" required rules="required" name="phone" id="phone"  placeholder="Phone"/>
                    <input type="hidden" name="sending_location" id="sending_location" v-model="url"/>
                    <input type="hidden" name="rental" id="rental" v-model="rental">
                </div>
                <div class="comments">
                    <textarea v-model="message" name="comments" id="comments" cols="30" rows="10" placeholder="Best time to contact & questions/comments.">
                    </textarea>
                </div>
            </div>
            <div class="g-recaptcha" data-sitekey="6LcL5ewoAAAAABgA35WKmpafRACtd9ZAMWAohKfW"></div>
            <button type="submit" class="btn" role="button" aria-label="Send">Send</button>
        </form>
    </div>
</template>

<script>
import kwesforms from 'kwesforms';
export default {
    name: "ContactForm",
    data(){
        return{
            fullname:'',
            email:'',
            phone:'',
            message:'',
            url: window.location.href,
            rental: 'no',
        }
    },
    watch:{
      $route (){
          this.url = window.location.href;
          this.rental = this.url.includes('rental') ? 'yes' : 'no';
      }
    },
    computed:{
        validateForms:function(){
            return this.fullname !== '' && this.email !== '' && this.phone !== '';
        },
    },
    mounted:function(){
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
        document.head.appendChild(recaptchaScript)
        kwesforms.init();
    },
}
</script>

<style lang="scss" scoped>
.contact_form{
    text-align: center;
    background: #f5f5f5;
    padding: 80px 0;
    h2{
        text-align: center;
        font-size: 3.25rem;
    }
    hr{
        opacity: 0.5;
        width: 300px;
        border-top: 1px solid #e1e1e1;
    }
    p{
        text-align: center;
    }
}

.form{
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    .info{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-end;
        input{
            background: #f5f5f5;
            height: 50px;
            margin: 1rem 0;
            width: 220px;
            padding: 10px;
            border: 1px solid #e1e1e1;
            font-size: 0.85rem;
            font-family: $primaryFont;
        }
    }
    .comments{
        width: 60%;
        display: flex;
        textarea{
            align-self: start;
            resize: none;
            width: 500px;
            background: #f5f5f5;
            height: 215px;
            margin-left: 1rem;
            border: 1px solid #e1e1e1;
            padding: 10px;
            font-size: 0.85rem;
            font-family: $primaryFont;
        }
    }
}

button{
    background: #f5f5f5;
    &:hover{
        cursor: pointer;
    }
}

.g-recaptcha{
    display: flex;
    justify-content: center;
}

@media (max-width: $mobile) {
    .contact_form{
        p{
            margin: 2rem;
        }
    }
    .form{
        display: block;
        .info{
            align-items: flex-start;
            input{
                margin: 1rem 2rem;
                width: 80%;
            }
        }
         .comments{
            width: 100%;
            textarea{
                align-self: start;
                resize: none;
                width: 80%;
                height: 215px;
                margin: 1rem 2rem;
                border: 1px solid #e1e1e1;
            }
        }
    }
}
</style>
