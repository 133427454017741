<template>
    <div class="accessibility" v-if="$store.getters.accessibilitySetting.showAccessibilityBar">
        <div class="enable-accessibility" v-if="showEnableAccessbilityBar">
            <button @click="toggleAccessibility">
                Enable Accessibility
            </button>
            <button class="btn_close" @click="turnOffAccessibility">
                <img src="/images/hamburger-close.png" alt="Close Accessibility Menu Bar">
            </button>
        </div>
        <div class="disable-accessibility" v-if="showDisableAccessbilityBar" >
            <button @click="toggleAccessibility">Disable Accessibility </button>
            <hr>
            <button v-bind:disabled="reachMax" @click="increaseFontSize">ZOOM IN <font-awesome-icon class="icon" :icon="['fa', `plus-circle`]" /></button>
            <hr>
            <button v-bind:disabled="reachMin" @click="decreaseFontSize">ZOOM OUT <font-awesome-icon class="icon" :icon="['fa', `minus-circle`]" /></button>
        </div>

    </div>
</template>

<script>
export default {
    name: "Accessibility",
    methods:{
        increaseFontSize(){
            this.$store.dispatch('increaseFontSize');
            const fontSize = this.$store.getters.accessibilitySetting.fontSize;
            document.querySelector('html').style.fontSize = `${fontSize}%`;
        },
        decreaseFontSize(){
            this.$store.dispatch('decreaseFontSize');
            const fontSize = this.$store.getters.accessibilitySetting.fontSize;
            document.querySelector('html').style.fontSize = `${fontSize}%`;
        },
        toggleAccessibility(){
            this.$store.dispatch('toggleAccessibilityMode');
            if(this.$store.getters.accessibilitySetting.accessibilityMode){
                let css = `a:active, .btn:active, button:active, img:active, input:active, textarea:active, a:focus, .btn:focus, button:focus, img:focus, input:focus, textarea:focus {
                    outline: 2px solid coral;
                    outline-offset: 5px;
                }`,
                head = document.head || document.getElementsByTagName('head')[0],
                style = document.createElement('style');
                style.id= "accessibility-style"

                head.appendChild(style);

                style.type = 'text/css';
                style.appendChild(document.createTextNode(css));
            } else {
                let head = document.head || document.getElementsByTagName('head')[0];
                let element = document.getElementById("accessibility-style");
                head.removeChild(element);
                document.querySelector('html').style.fontSize = `100%`;
            }
        },
        turnOffAccessibility(){
            this.$store.dispatch('turnOffAccessibilityMode');
        }
    },
    computed:{
        showEnableAccessbilityBar(){
            return this.$store.getters.accessibilitySetting.showAccessibilityBar &&
            !this.$store.getters.accessibilitySetting.accessibilityMode
        },
        showDisableAccessbilityBar(){
            return this.$store.getters.accessibilitySetting.showAccessibilityBar &&
            this.$store.getters.accessibilitySetting.accessibilityMode
        },
        reachMax(){
            return this.$store.getters.accessibilitySetting.fontSize === 150;
        },
        reachMin(){
            return this.$store.getters.accessibilitySetting.fontSize === 100;
        }
    }
}
</script>

<style lang="scss" scoped>
.accessibility{
    z-index: 4;
    background: #000;
    position: sticky;
    top: 0;
    padding: 12px 16px;
    height: 44px;
}
.enable-accessibility{
    justify-content: space-between;
}
.disable-accessibility, .enable-accessibility{
    display:flex;
    align-items: center;
}
button{
    cursor: pointer;
    background: #000;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    padding: 0 16px;
    font-size: 12px;
    &:disabled{
        color: #ccc;
        &:hover{
            cursor: not-allowed;
        }
    }
    &.btn_close{
        padding:0;
        margin-right: 5px;
    }
}
img{
    display: block;
    height: 20px;
}
hr{
    display: block;
    margin: 0;
    border: none;
    border-left:1px solid #fff;
    height:20px
}
</style>
