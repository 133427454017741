<template>
    <div class="socialLinks">
        <a v-for="item in socialMediaItems" :key="item.icon" v-bind:href="item.url" target="_blank" class="socialLink"
            :aria-label="`Go to Cerda Zein ${item.ariaLabel} Home Page`">
            <font-awesome-icon :icon="['fab', `${item.icon}`]" class="socialIcon"/>
        </a>
    </div>
</template>

<script>
export default {
    name: "FooterSocialMedia",
    data(){
        return{
            socialMediaItems: [
                {
                    icon: 'yelp',
                    url:"https://www.yelp.com/biz/cerda-zein-real-estate-alameda-2",
                    ariaLabel: 'Yelp'
                },
                {
                    icon: 'facebook-square',
                    url:"https://www.facebook.com/CerdaZeinRealEstate",
                    ariaLabel: 'Facebook'
                },
                {
                    icon: 'instagram',
                    url:"https://www.instagram.com/cerdazeinrealestate",
                    ariaLabel: 'Instagram'
                },
                
            ]
        }
    }

}
</script>

<style lang="scss" scoped>
.socialLinks{
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}
.socialLink{
    margin: 0 0.75rem;
}
.socialIcon{
    font-size: 1.6rem;
    color: white;
    &:hover{
        opacity: 0.85;
    }
}
</style>